export type Video = {
	content_provider_id?: number
	id?: number
	remote_id: string
	url?: string
}

interface Vimeo {
	description: string
	duration: number
	embed_privacy: string
	height: number
	id: number
	stats_number_of_comments: number
	stats_number_of_likes: number
	stats_number_of_plays: number
	tags: string
	thumbnail_large: string
	thumbnail_medium: string
	thumbnail_small: string
	title: string
	upload_date: string
	url: string
	user_id: number
	user_name: string
	user_portrait_huge: string
	user_portrait_large: string
	user_portrait_medium: string
	user_portrait_small: string
	user_url: string
	width: number
}

export interface VideoSlider {
	thumbnail: string
	video: string
}

/**
 * @description Creates an instance of Video and gets the remote id from an URL
 * @param url
 * @returns
 */
export function getVideoDataFromUrl(url: string) {
	let videoData: Video = {
		remote_id: ''
	}
	if (url.includes('youtube.com') || url.includes('youtu.be')) {
		const youtubePattern = /(?:\?v=|&v=|youtu\.be\/)(.*?)(?:&|$)/
		const match = url.match(youtubePattern)
		videoData.remote_id = match ? match[1] : ''
		videoData.content_provider_id = 1
	} else if (url.includes('vimeo.com')) {
		const vimeoPattern = /vimeo\.com\/(\d+)/
		const match = url.match(vimeoPattern)
		videoData.remote_id = match ? match[1] : ''
		videoData.content_provider_id = 2
	}
	return videoData
}

/**
 * @description Fetches the data from vimeo api ( embed url & thumbnail )
 * @param remoteId
 * @returns
 */
const getVimeoData = async (remoteId: string): Promise<Vimeo | null> => {
	try {
		const response = await fetch(`https://vimeo.com/api/v2/video/${remoteId}.json`, {
			method: 'GET'
		})

		if (!response.ok) {
			throw new Error('Failed to fetch Vimeo data')
		}

		const data: Vimeo[] | null = await response.json()
		return data ? data[0] : null
	} catch (error) {
		console.error('Error fetching Vimeo data:', error)
		return null
	}
}

/**
 * @description Gets the video data with vimeo format
 * @param video
 * @returns
 */
const getVimeoVideo = async (video: Video): Promise<VideoSlider | null> => {
	const { remote_id } = video

	const data: Vimeo | null = await getVimeoData(remote_id)

	if (!data) {
		return null
	}

	return {
		thumbnail: `${data.thumbnail_large}`,
		video: `https://player.vimeo.com/video/${data.id}`
	}
}

/**
 * @description Gets the video data with YT format
 * @param video
 * @returns
 */
const getYoutubeData = (video: Video): VideoSlider | null => {
	const { remote_id } = video

	return {
		thumbnail: `https://img.youtube.com/vi/${remote_id}/hqdefault.jpg`,
		video: `https://www.youtube.com/embed/${remote_id}?autoplay=1`
	}
}

/**
 * @description Gets the url for the video and the thumbnail depending on the provider
 * @param video
 * @returns
 */
export const getVideoConfig = async (video: Video): Promise<VideoSlider | null> => {
	const { content_provider_id } = video

	if (content_provider_id === 2) {
		return await getVimeoVideo(video)
	} else {
		return getYoutubeData(video)
	}
}
